<mat-toolbar>
  <div (click)="showSideBar()" [hidden]="!authService.isAuthBDD" class="icon_holder d-md-none">
    <img alt="" id="menu_icon" src="../../../../assets/images/menu_icon.svg">
  </div>
  <div class="search_container"></div>

  <div class="title  d-flex flex-column align-items-start">
    <h1 [ngClass]="{'red': isRed}">{{title}}</h1>
    <div class="maj-text d-md-none">
      <div class="d-flex flex-column align-items-start">
        <span>Mise à jour : {{dateBuild}}</span>
        <!--<span>Version {{version}}</span>-->
      </div>
    </div>
  </div>
  <span class="fill-space"></span>
  @if (!isLoggedIn()) {
    <button (click)="logoutService.logout()"
            class="btn btn-danger"
            type="button">Se déconnecter
    </button>
  }

  <div class="maj-text d-md-block d-none">
    <div class="d-flex flex-column  align-items-end">
      <span>Mise à jour : {{dateBuild}}</span>
      <!--<span>Version {{version}}</span>-->
    </div>
  </div>
  <div class="icon">
    <img alt="" src="../../../../assets/images/logo_sport_ville.svg">
  </div>
</mat-toolbar>
