import {Young} from './young.model';

export class YoungImageRight {
  id?: number;
  idYoung?: number;
  hasImageRight: boolean;
  imageRightAutorizationDate: Date;
  imageRightAutorizationUserId: number;

  young?: Young;
}

export const getLatestImageRight = (array: YoungImageRight[]): YoungImageRight =>
  array?.reduce((a, b) => a?.imageRightAutorizationDate > b?.imageRightAutorizationDate ? a : b, array[0] ?? null);
